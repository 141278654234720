import React from 'react';
import Header from 'cccisd-header';
import style from './style.css';
const { wpUrl } = window.cccisd.appDefs.app;
const Fortress = window.cccisd.fortress;

const AppHeader = () => {
    // const WordpressLinks = () => {
    //     return (
    //         <ul className={`nav navbar-nav ${style.wordpressLinks}`} style={{ marginTop: '20px' }}>
    //

    //             <NavbarLink to="/resourceCenter">Resources</NavbarLink>
    //             <NavbarLink to={`${wpUrl}/policy-blog`}>Policy Blog</NavbarLink>
    //             {Fortress.isSuperUser() && (
    //                 <>
    //                     <NavbarLink to="/viewData">View Data</NavbarLink>
    //                     <NavbarLink to="/manage">Manage</NavbarLink>
    //                 </>
    //             )}
    //             <NavbarContent
    //                 allowSwitchRoles={false}
    //                 loginLinkTo="/register"
    //                 loginLinkLabel="Login/Register"
    //             />
    //         </ul>
    //     );
    // };
    const primaryNavLinks = [
        {
            url: `${wpUrl}/`,
            label: 'Welcome',
            children: [
                { url: `${wpUrl}/about-us`, label: 'About Us' },
                { url: `${wpUrl}/contact`, label: 'Contact Us' },
            ],
        },
        { url: `${wpUrl}/cost-calculator`, label: 'Cost Calculator' },
        { url: `${wpUrl}/implementation-roadmap`, label: 'Professional Development' },
        { url: `${wpUrl}/intervention-hub`, label: 'Intervention Hub' },
        { url: `${wpUrl}/implementation-hub`, label: 'Implementation Hub' },
        { url: '/resourceCenter', label: 'Resources' },
        { url: `${wpUrl}/policy-blog`, label: 'Policy Blog' },
    ];
    const uberNavLinks = [
        {
            url: `${wpUrl}/`,
            label: 'Welcome',
            children: [
                { url: `${wpUrl}/about-us`, label: 'About Us' },
                { url: `${wpUrl}/contact`, label: 'Contact Us' },
            ],
        },
        { url: `${wpUrl}/cost-calculator`, label: 'Cost Calculator' },
        { url: `${wpUrl}/implementation-roadmap`, label: 'Professional Development' },
        { url: `${wpUrl}/intervention-hub`, label: 'Intervention Hub' },
        { url: `${wpUrl}/implementation-hub`, label: 'Implementation Hub' },
        { url: '/resourceCenter', label: 'Resources' },
        { url: `${wpUrl}/policy-blog`, label: 'Policy Blog' },
        { url: '/viewData', label: 'View Data' },
        { url: '/manage', label: 'Manage' },
    ];

    return (
        <Header
            primaryNavLinks={Fortress.user.acting.data_type === 'uberadmin' ? uberNavLinks : primaryNavLinks}
            containerClassName="container-fluid"
            wrapperClassName={style.wrapper}
            showHomeAction={false}
            showAlertAction={false}
            logoClassName={style.logo}
            logoLink="https://www.ruralsmh.com/"
        />
    );
};

export default AppHeader;
